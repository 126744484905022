import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

const WorkOrderTemplateSelectButton = ({
  workOrderTemplates,
  onTemplateClick,
}: {
  workOrderTemplates: { id: number; name: string }[]; // TODO: fragmentで型を取得できるようにする
  onTemplateClick: (templateId: number) => Promise<void>;
}) => {
  const { t_ns } = useTranslation(TASK);

  const onMenuButtonClick = () => {
    if (workOrderTemplates.length === 0) {
      throw new Error('テンプレートがありません');
    }

    if (workOrderTemplates.length === 1) {
      onTemplateClick(workOrderTemplates[0].id);
    }
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          variant='outline'
          colorScheme='primary'
          size='sm'
          onClick={onMenuButtonClick}
        >
          {t_ns('new-link-task')}
        </MenuButton>
        {/* テンプレートが1つの時はメニューを表示しない */}
        {workOrderTemplates.length > 1 && (
          <MenuList>
            {workOrderTemplates.map((template) => (
              <MenuItem key={template.id} onClick={() => onTemplateClick(template.id)}>
                {template.name}
              </MenuItem>
            ))}
          </MenuList>
        )}
      </Menu>
    </>
  );
};

export default WorkOrderTemplateSelectButton;
